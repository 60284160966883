<script>
  export default {
    data () {
      return {
        breadcrumbList: [],
      }
    },
    watch: {
      $route () {
        this.updateList()
      },
    },
    mounted () {
      this.updateList()
    },
    computed: {
      /**
       * @see https://medium.com/@pratheekhegde/displaying-application-breadcrumbs-in-vue-js-85456dc8a370
       */
      breadCrumbs () {
        let pathArray = this.$route.path.split('/')
        // console.log(pathArray)
        pathArray.shift()
        const breadCrumbs  = []
        // needed to handle the intermediary entries for nested vue routes
        let breadcrumb     = ''
        let breadcrumbAll     = ''
        let lastIndexFound = 0

        for (let i = 0; i < pathArray.length; ++i) {
          breadcrumb = `${breadcrumb}${'/'}${pathArray[i]}`
          breadcrumbAll += breadcrumb

          // console.log(breadcrumb)
          // console.log(Object.hasOwnProperty.call(this.$route.matched[i], 'meta'))
          if (
            this.$route.matched[i]
            // && Object.hasOwnProperty.call(this.$route.matched[i], 'meta')
            // && Object.hasOwnProperty.call(this.$route.matched[i].meta, 'breadcrumb')
          ) {
            // console.log(Object.hasOwnProperty.call(this.$route.matched[i], 'link'))
            // console.log(this.$route.matched[i])
            if (
              Object.hasOwnProperty.call(this.$route.matched[i].meta, 'link')
              && this.$route.matched[i].meta.link === false) {
              // console.log(this.$route.matched[i].link)
              continue
            }

            breadCrumbs.push({
              // link: i !== 0 && pathArray[i - (i - lastIndexFound)]
              //   ? '/' + pathArray[i - (i - lastIndexFound)] + breadcrumb
              //   : breadcrumb,
              link: i + 1 !== pathArray.length ? breadcrumbAll : null,
              disabled: i + 1 === pathArray.length,
              name: this.$route.matched[i].name || pathArray[i]
            })
            lastIndexFound = i
            breadcrumb     = ''
          }
        }
        // console.log(breadCrumbs)
        return breadCrumbs
      }
    },
    methods: {
      routeTo (pRouteTo) {
        if (this.breadcrumbList[pRouteTo].link) {
          this.$router.push(this.breadcrumbList[pRouteTo].link)
        }
      },
      updateList () {
        // this.breadcrumbList = this.$route.meta.breadcrumb
        this.breadcrumbList = this.breadCrumbs
      },
    },
  }
</script>

<template>
  <v-toolbar
    class="subheader"
    flat
    color=""
    width="100%"
  >
    <div class="d-flex justify-space-between align-center flex-wrap">
      <div class="d-flex align-center subheader-left">
        <ul class="egret-breadcrumb-li">
          <li
            v-for="(breadcrumb, idx) in breadcrumbList"
            :key="idx"
            class="text--primary"
            :class="{linked: !!breadcrumb.link}"
            @click="routeTo(idx)"
          >
            {{ breadcrumb.name }}
          </li>
        </ul>
      </div>
    </div>
  </v-toolbar>
</template>

<style lang="scss">
.subheader {
  flex: 0;
  //position: fixed;
  top: 75px;
  z-index: 2;
  width: calc(100% - 256px);
  margin-left: 256px;
  transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);

  .subheader-left {
    @media (max-width: 1000px) {
      display: none !important;
    }
  }

  .v-toolbar__content {
    width: calc(100% - 256px) !important;
    transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
    @media (max-width: 959px) {
      width: 100% !important;
      margin-left: 0px;
    }
  }
  @media (max-width: 959px) {
    width: 100%;
    margin-left: 0px;
  }
}

// sidebar-mini
.sidebar-mini {
  .subheader {
    .v-toolbar__content {
      width: 100% !important;
      margin-right: 56px;
      // transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
    }

    width: 100%;
    margin-left: 0;
    left: 56px;
    transition: all 2s cubic-bezier(0.685, 0.0473, 0.346, 1);
  }
}

// sidebar-close
.sidebar-close {
  .subheader {
    .v-toolbar__content {
      width: 100% !important;
      margin-right: 0;
      transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
    }

    width: 100%;
    margin-left: 0;
    transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
  }
}

ul.egret-breadcrumb-li {
  display: flex;
  justify-content: center;
  list-style-type: none;
  margin: 0;
  padding: 0;

  li {
    display: flex;
    float: left;

    width: auto;

    font-weight: bold;
    // color: #828D99 !important;
    cursor: default;
    align-items: center;

    &:not(:last-child)::after {
      content: '\F0142';
      font: normal normal normal 24px/1 'Material Design Icons';
      float: right;
      margin: 0 0.2em;

      cursor: default;
    }

    &.linked {
      cursor: pointer;
      font-size: 1em;
      font-weight: normal;
    }
  }
}
</style>
